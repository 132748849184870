import React, { useCallback } from 'react';
import { useUpdate, useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import {
    Dialog, DialogContent, DialogActions, Button, Box
} from '@mui/material';

import editConfig from 'APP/configs/editConfig';
import FormInput from 'APP/components/form/FormInput';
import FormSelect from 'APP/components/form/FormSelect';
import PromptIfDirty from 'APP/components/package/PromptIfDirty';
import utility from 'APP/utility';

// 確認是否為非 順豐 或 自取 或 GOODMAJI 或 KICKSCREW 物流
const checkIsNeedFillShippingNumber = (data = {}) => !!(data?.shipping !== editConfig.shippingCode.sf && data?.shipping !== editConfig.shippingCode.self && data?.shipping !== editConfig.shippingCode.goodmaji && data?.shipping !== editConfig.shippingCode.kicksCrew);

function ChooseShippingTypePopup ({
    isOpen,
    handleClose,
    formRef,
    shippingTypes,
    handleOpenSuccessConfirm,
    handleFailedMessage,
    handleOpenSuccessConfirmForShipping,
}) {
    
    const { values: ParentFormValues } = useFormikContext();
    const { record } = useEditContext();
    const [update] = useUpdate();

    const handleClosePop = useCallback((_, reason) => {

        formRef.current.resetForm();
        handleClose(_, reason);

    }, [formRef, handleClose]);

    const handleFormSubmit = useCallback((data) => {

        const newData = {
            status: ParentFormValues.status,
            action: editConfig.actionType.formSubmit,
        };
        const preData = {
            status: record?.status,
        };

        const newShippingData = utility.dealWithUpdateNewShippingData(data.shipping, data.shippingNumber);
        const { newParams, preParams } = utility.dealWithUpdateCommonData(ParentFormValues, record);

        update(
            editConfig.resource,
            {
                id: record?.id,
                data: { ...newData, ...newShippingData, ...newParams },
                previousData: { ...preData, ...preParams }
            },
            {
                // enabled: data.status === record?.status,
                onSuccess: (successData) => {

                    //  順豐速遞
                    if (successData?.shippingInfo?.shippingCode === editConfig.shippingCode.sf || successData?.shippingInfo?.shippingCode === editConfig.shippingCode.goodmaji || successData?.shippingInfo?.shippingCode === editConfig.shippingCode.kicksCrew) {

                        const shippingName = shippingTypes.find((item) => item.id === successData.shippingInfo.shippingCode)?.name;

                        handleOpenSuccessConfirmForShipping(`${shippingName}運單取號`, successData?.shippingInfo?.shippingLabelUrl);

                    }
                    //  非順豐速遞
                    else {

                        handleOpenSuccessConfirm();

                    }

                    handleClosePop();

                },
                onError: (err) => {

                    //  順豐速遞
                    if (data?.shipping === editConfig.shippingCode.sf || data?.shipping === editConfig.shippingCode.goodmaji || data?.shipping === editConfig.shippingCode.kicksCrew) {

                        const shippingName = shippingTypes.find((item) => item.id === data.shipping)?.name;

                        handleFailedMessage(`${shippingName}運單取號`, `取號失敗 ${err?.message && `(${err?.message})`}`);
                    
                    }
                    //  非順豐速遞
                    else {

                        handleFailedMessage(`Updated # ${record?.packageNumber}`, `更新失敗 ${err?.message && `(${err?.message})`}`);

                    }

                    handleClosePop();

                }
            }
        );

    }, [ParentFormValues, record, update, handleClosePop, handleOpenSuccessConfirmForShipping, handleOpenSuccessConfirm, handleFailedMessage]);

    const schema = Yup.object({
        shipping: Yup.string().required(editConfig.errorMsg.required),
        shippingNumber: Yup.string().when('shipping', {
            is: (val) => checkIsNeedFillShippingNumber({ shipping: val }),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
    });

    return (
        <Dialog
            open={isOpen}
            onClose={handleClosePop}
            fullWidth
            maxWidth="sm"
        >
            <Formik
                initialValues={{
                    shipping: '',
                    shippingNumber: '',
                }}
                validationSchema={schema}
                onSubmit={handleFormSubmit}
                innerRef={formRef}
                validateOnChange={false}
            >
                {({
                    handleChange, handleSubmit, values, errors
                }) => (
                    <>
                        <DialogContent>
                            <FormSelect
                                labelName="請選擇商品寄送方式"
                                labelId="popShippingLabel"
                                options={shippingTypes}
                                value={values.shipping}
                                handleChange={handleChange('shipping')}
                                error={errors.shipping}
                                // formControlExtraStyle={{ maxWidth: '50%' }}
                            />
                            {
                                !!(values.shipping && checkIsNeedFillShippingNumber(values)) && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormInput
                                            labelName="運單編號"
                                            value={values.shippingNumber}
                                            handleChange={handleChange('shippingNumber')}
                                            error={errors.shippingNumber}
                                        />
                                    </Box>
                                )
                            }
                            <PromptIfDirty />
                        </DialogContent>
                        <DialogActions
                            sx={{
                                marginRight: '14px',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}

ChooseShippingTypePopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formRef: PropTypes.object.isRequired,
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    handleOpenSuccessConfirm: PropTypes.func.isRequired,
    handleFailedMessage: PropTypes.func.isRequired,
    handleOpenSuccessConfirmForShipping: PropTypes.func.isRequired,
};

ChooseShippingTypePopup.defaultProps = {
    shippingTypes: [],
};

export default ChooseShippingTypePopup;
