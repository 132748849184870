import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Button
} from '@mui/material';

function OpenShippingLabelBtn ({ labelUrl, text = '' }) {

    const handlePrintShippingLabel = useCallback(() => {

        labelUrl && window.open(labelUrl, '_blank');

    }, []);

    return (
        <Button
            size="medium"
            onClick={handlePrintShippingLabel}
        >
            {text || '列印運單標籤'}
        </Button>
    );

}

OpenShippingLabelBtn.propTypes = {
    labelUrl: PropTypes.string.isRequired,
    text: PropTypes.string
};

OpenShippingLabelBtn.defaultProps = {
    text: ''
};

export default OpenShippingLabelBtn;
