const errorMsg = {
    required: '此為必填欄位'
};

const common = {
    emptyString: '-'
};

const resource = 'package';

const actionType = {
    formSubmit: 'save_form',
    refetchShippingNumber: 'refetch_shipping_number'
};

const fetchListQueryOptions = {
    retry: 5,
    refetchInterval: 10 * 1000,
    cacheTime: 60 * 60 * 1000
};

const shippingCode = {
    // 順豐速遞
    sf: 'sf',
    // 自取
    self: 'self',
    // GOODMAJI
    goodmaji: 'goodmaji',

    kicksCrew: 'kicks_crew',
    // 包退入倉
    returnWarehouse: 'dts',
};

const logFieldName = {
    packageStatus: '處理狀態',
    storehouse: '倉庫',
    shelves: '貨架',
    shippingName: '物流商',
    shippingNumber: '運單編號',
    revertShippingName: '退回賣家物流商',
    revertShippingNumber: '退回賣家運單編號',
    cancelType: '異常狀態',
    cancelReason: '異常原因',
    detailType: '取消原因備註',
    adminMemo: '備註',
    rfid: 'RFID',
    originCountry: '產地',
    productionType: '報關品名',
    weight: '重量',
    packageSize: '尺寸',
    productionCustomsCode: '正式報關單號',
    insuranceCode: '保險單號',
    recipientPhone: '收件人手機',
    recipientZipcode: '郵遞區號',
    recipientName: '收件人姓名',
    recipientCity: '城市',
    recipientDistrict: '行政區',
    recipientAddress: '收件人地址',
    returnRecipientZipcode: '退貨郵遞區號',
    returnRecipientCity: '退貨城市',
    returnRecipientDistrict: '退貨行政區',
    returnRecipientAddress: '商品退貨地址',
    area02Memo: 'AREA 02 備註',
    shippingFeePayer: '運費負擔方'
};

export default {
    common,
    errorMsg,
    actionType,
    resource,
    fetchListQueryOptions,
    shippingCode,
    logFieldName
};
