import React, { useCallback } from 'react';
import { useUpdate, useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import {
    Dialog, DialogContent, DialogActions, Button, Box
} from '@mui/material';

import editConfig from 'APP/configs/editConfig';
import FormInput from 'APP/components/form/FormInput';
import FormSelect from 'APP/components/form/FormSelect';
import PromptIfDirty from 'APP/components/package/PromptIfDirty';
import utility from 'APP/utility';

// 自動取號的物流商
const autoGetNumberShippingCodes = [editConfig.shippingCode.sf];
// 確認是否為非 順豐 或 自取 或 包退入倉 的物流商
const checkIsNeedFillShippingNumber = (data = {}) => !!(data.shipping !== editConfig.shippingCode.self && data.shipping !== editConfig.shippingCode.returnWarehouse && !autoGetNumberShippingCodes.includes(data.shipping));

function RevertShippingPopup ({
    isOpen,
    handleClose,
    formRef,
    shippingTypes,
    handleOpenSuccessConfirm,
    handleOpenSuccessConfirmForShipping,
    handleFailedMessage
}) {

    const { values: ParentFormValues } = useFormikContext();
    const { record } = useEditContext();
    const [update] = useUpdate();

    const handleClosePop = useCallback((_, reason) => {

        formRef.current.resetForm();
        handleClose(_, reason);

    }, [formRef, handleClose]);

    const handleFormSubmit = useCallback((data) => {

        const newData = {
            status: ParentFormValues.status,
            action: editConfig.actionType.formSubmit,
        };
        const preData = {
            status: record?.status,
            shippingCode: record?.revertShippingInfo?.shippingCode,
            shippingNumber: record?.revertShippingInfo?.shippingNumber
        };

        const newShippingData = utility.dealWithUpdateNewShippingData(data.shipping, data.shippingNumber, true, data.shippingFeePayer);
        const { newParams, preParams } = utility.dealWithUpdateCommonData(ParentFormValues, record);

        // console.log('package@@@ newData', { ...newData, ...newShippingData, ...newParams }, handleOpenSuccessConfirmForShipping);
        // console.log('package@@@ preData', { ...preData, ...preParams });

        update(
            editConfig.resource,
            {
                id: record?.id,
                data: { ...newData, ...newShippingData, ...newParams },
                previousData: { ...preData, ...preParams }
            },
            {
                onSuccess: (successData) => {

                    if (successData?.revertShippingInfo?.shippingLabelUrl) {

                        const shippingName = shippingTypes.find((item) => item.id === successData.revertShippingInfo.shippingCode)?.name;

                        handleOpenSuccessConfirmForShipping(`${shippingName}運單取號`, successData.revertShippingInfo.shippingLabelUrl, '列印退貨運單標籤');
                        
                    }
                    //  沒有取號的情況
                    else {

                        handleOpenSuccessConfirm();

                    }
                    handleClosePop();

                },
                onError: (error) => {

                    handleFailedMessage(`Updated # ${record?.packageNumber}`, `更新失敗 ${error?.message && `(${error?.message})`}`);
                    handleClosePop();

                }
            }
        );

    }, [handleClosePop, handleFailedMessage, handleOpenSuccessConfirm, record, update, ParentFormValues]);

    const schema = Yup.object({
        shipping: Yup.string().required(editConfig.errorMsg.required),
        shippingNumber: Yup.string().when('shipping', {
            is: (val) => checkIsNeedFillShippingNumber({ shipping: val }),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        shippingFeePayer: Yup.string().when('shipping', {
            is: (val) => (val !== editConfig.shippingCode.self && val !== editConfig.shippingCode.returnWarehouse),
            then: Yup.string().required(editConfig.errorMsg.required)
        })
    });

    return (
        <Dialog
            open={isOpen}
            onClose={handleClosePop}
            fullWidth
            maxWidth="sm"
        >
            <Formik
                initialValues={{
                    shipping: '',
                    shippingNumber: '',
                    shippingFeePayer: ''
                }}
                validationSchema={schema}
                onSubmit={handleFormSubmit}
                innerRef={formRef}
                validateOnChange={false}
            >
                {({
                    handleChange, handleSubmit, values, errors
                }) => (
                    <>
                        <DialogContent>
                            <FormSelect
                                labelName="物流商"
                                labelId="popRevertShippingLabel"
                                options={shippingTypes}
                                value={values.shipping}
                                handleChange={handleChange('shipping')}
                                error={errors.shipping}
                            />
                            {/* 非自取及自動取號 */}
                            {
                                !!(values.shipping && checkIsNeedFillShippingNumber(values)) && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormInput
                                            labelName="運單編號"
                                            value={values.shippingNumber}
                                            handleChange={handleChange('shippingNumber')}
                                            error={errors.shippingNumber}
                                        />
                                    </Box>
                                )
                            }
                            {/* 非自取 */}
                            {
                                (values.shipping !== editConfig.shippingCode.self && values.shipping !== editConfig.shippingCode.returnWarehouse) && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormSelect
                                            labelName="運費負擔方"
                                            labelId="popRevertShippingFeePayer"
                                            options={shippingTypes.find((item) => item.id === values.shipping)?.shippingFeePayers || []}
                                            value={values.shippingFeePayer}
                                            handleChange={handleChange('shippingFeePayer')}
                                            error={errors.shippingFeePayer}
                                        />
                                    </Box>
                                )
                            }
                            <PromptIfDirty />
                        </DialogContent>
                        <DialogActions
                            sx={{
                                marginRight: '14px',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}

RevertShippingPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formRef: PropTypes.object.isRequired,
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    handleOpenSuccessConfirm: PropTypes.func.isRequired,
    handleOpenSuccessConfirmForShipping: PropTypes.func.isRequired,
    handleFailedMessage: PropTypes.func.isRequired
};

RevertShippingPopup.defaultProps = {
    shippingTypes: [],
};

export default RevertShippingPopup;
