import React from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Grid
} from '@mui/material';
import { useFormikContext } from 'formik';

import utility from 'APP/utility';
import FormSelect from 'APP/components/form/FormSelect';
import FormInput from 'APP/components/form/FormInput';
import editConfig from 'APP/configs/editConfig';

function RevertShipping ({
    isEdit,
    shippingTypes,
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();
    // const options = shippingTypes.filter((item) => item.id !== editConfig.shippingCode.sf);
    const editable = record?.revertShippingInfo?.shippingCode !== editConfig.shippingCode.sf;

    const handleChangeShipping = (e) => {

        formik.setFieldValue('revertShipping', e.target.value);
        formik.setFieldValue('revertShippingNumber', '');
        formik.setFieldValue('revertShippingFeePayer', '');

    };

    return (
        <div className="revertShipping block">
            <div className="title">退回賣家 寄送資訊</div>
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableTitle">物流商</Grid>
                <Grid item xs={4} md={4} className="tableItem tableTitle">運單編號</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">寄送日期</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">運費負擔方</Grid>
            </Grid>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">
                    {
                        editable ? (
                            <FormSelect
                                options={shippingTypes}
                                value={formik.values.revertShipping}
                                handleChange={handleChangeShipping}
                                error={formik.errors.revertShipping}
                                disabled={!isEdit}
                                isEdit={isEdit}
                                formControlVariant="standard"
                            />
                        ) : (record?.revertShippingInfo?.shippingName || editConfig.common.emptyString)
                    }
                </Grid>
                <Grid item xs={4} md={4} className="tableItem tableItemBody">
                    {
                        (isEdit) ? (
                            editable ? (
                                <FormInput
                                    value={formik.values.revertShippingNumber}
                                    handleChange={formik.handleChange('revertShippingNumber')}
                                    error={formik.errors.revertShippingNumber}
                                    disabled={!isEdit}
                                    variant="standard"
                                />
                            ) : (record?.revertShippingInfo?.shippingNumber || editConfig.common.emptyString)
                        ) : (record?.revertShippingInfo?.shippingNumber || editConfig.common.emptyString)
                    }
                </Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{utility.formateTime(record?.revertShippingInfo?.shippingAt)}</Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">
                    {
                        (isEdit) ? (
                            editable ? (
                                <FormSelect
                                    options={shippingTypes.find((item) => item.id === formik.values.revertShipping)?.shippingFeePayers || []}
                                    value={formik.values.revertShippingFeePayer}
                                    handleChange={formik.handleChange('revertShippingFeePayer')}
                                    error={formik.errors.revertShippingFeePayer}
                                    disabled={!isEdit}
                                    isEdit={isEdit}
                                    formControlVariant="standard"
                                />
                            ) : (record?.revertShippingInfo?.shippingFeePayer || editConfig.common.emptyString)
                        ) : (record?.revertShippingInfo?.shippingFeePayer || editConfig.common.emptyString)
                    }
                </Grid>
            </Grid>
        </div>
    );

}

RevertShipping.propTypes = {
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    isEdit: PropTypes.bool.isRequired
};

RevertShipping.defaultProps = {
    shippingTypes: []
};

export default RevertShipping;
