import React from 'react';
import { useRecordContext } from 'react-admin';

function SubOrderLinkField (props) {

    const record = useRecordContext(props);
    // const erpUrl = process.env.REACT_APP__ERP_URL;
    const consoleUrl = process.env.REACT_APP__CONSOLE_URL;

    return (
        <a href={`${consoleUrl}/#/v1/order/${record.orderId}/show`} target="_blank" rel="noreferrer">
            {record.subOrderId}
        </a>
    );

}

export default React.memo(SubOrderLinkField);
